<template>
    <div class="result">
        <div
            class="result-body"
            :class="{'result-success': isSuccess, 'result-fail': isFail}"
            v-if="params.deal">
            <div class="result-body_label" :style="{'background': `var(--main-result-${params.deal.status})`}">
                <b-icon :icon="isSuccess ? 'check' : 'x'" />
            </div>
            <h1 :style="{'color': `var(--main-result-${params.deal.status})`}"  v-html="$content(`result-${params.deal.status}_title`)"></h1>
            <div class="result-body_info">

                <div class="result-body_info_ps" v-if="isFail">
                    <div class="result-body_info_ps_image">
                        <img :src="this.imageSelector(params.deal.first_ps.logo_url)" alt="" />
                    </div>
                    <img class="result-body_info_ps_arrow" src="/assets/img/red-arrow.png" alt="">
                    <div class="result-body_info_ps_image">
                        <img :src="this.imageSelector(params.deal.last_ps.logo_url)" alt="" />
                    </div>
                </div>

                <div class="result-body_info_ps" v-if="isSuccess">
                    <div class="result-body_info_ps_image">
                        <img :src="this.imageSelector(params.deal.first_ps.logo_url)" alt="" />
                    </div>
                    <img class="result-body_info_ps_arrow" src="/assets/img/red-arrow.png" alt="">
                    <div class="result-body_info_ps_image">
                        <img :src="this.imageSelector(params.deal.last_ps.logo_url)" alt="" />
                    </div>
                </div>

                <div class="result-body_info_payout" v-if="isSuccess">
                    <p>+{{ $store.getters.static.symbol }}{{ params.deal.amount_final }}</p>
                    <div class="result-body_info_payout_body">
                        <span>{{ $store.getters.static.symbol }}{{ $store.getters.wallet.balances.amount }}</span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Result",
    computed: {
        params() {
            return this.$store.getters.activeModalParams
        },
        isSuccess() {
            return this.params && this.params.deal.status === 'success'
        },
        isFail() {
            return this.params && this.params.deal.status === 'fail'
        }
    },
    created() {

        if (this.params.audio) {
            console.log(this.params.audio)
            const audio = new Audio('/assets/sounds/23.mp3');
            audio.play();
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";

    .result {
        width: 565px;
        border-radius: 15px;
        background: white;
        height: 370px;
        padding-top: 35px;

        &-fail {

            & h1 {
                font-size: 25px;
                text-transform: uppercase;
                letter-spacing: 1.3px;
            }
        }

        &-success {

            & h1 {
                font-size: 25px;
                text-transform: uppercase;
                letter-spacing: 1.3px;
            }
        }


        &-body {
            display: flex;
            flex-direction: column;
            align-items: center;

            &_info {
                display: flex;
                width: 100%;
                justify-content: center;
                gap: 15px;

                @include only-xs {
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                    margin-bottom: 25px;
                }

                &_payout {
                    display: flex;
                    background: #ecf8d9;
                    align-items: center;
                    border-radius: 10px;

                    & p {
                        margin: 0 14px;
                        line-height: 1;
                        font-family: "Segoe UI Light", sans-serif;
                        font-size: 27px;
                        color: var(--main-app);
                        font-weight: bold;

                        @include only-xs {
                            font-size: 20px;
                        }
                    }

                    &_body {
                        background: var(--main-result-success);
                        border-radius: 10px;
                        font-family: "Segoe UI Light", sans-serif;
                        height: 50px;
                        font-size: 28px;
                        display: flex;
                        align-items: center;
                        color: white;
                        padding: 0 22px;

                        @include only-xs {
                            font-size: 21px;
                            padding: 0 15px;
                        }
                    }
                }

                &_ps {
                    display: flex;
                    align-items: center;
                    height: 50px;

                    &_image {
                        height: 50px;
                        width: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        & img {
                            height: auto;
                            width: 100%;
                        }
                    }

                    &_arrow {
                        height: 20px !important;
                        margin: 0 15px;
                        width: auto !important;
                    }
                }
            }

            & h1 {
                margin: 30px 0 50px 0;
                text-align: center;
                font-family: Montserrat-Medium, sans-serif;
                font-size: 24px;

                @include only-xs {
                    font-size: 20px;
                }
            }

            &_label {
                height: 80px;
                width: 80px;
                border-radius: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 17px;

                @include only-xs {
                    height: 60px;
                    width: 60px;
                }

                & svg {
                    transform: scale(4);
                    color: white;
                }
            }
        }

        @include only-xs {
            width: 100%;
            padding: 35px 10px 10px 10px;
        }
    }
</style>